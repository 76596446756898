@import "palettes";
@import "theme";
@import "mixins";

.card {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: var(--width);
  max-width: 100%;
  margin: 20px;

  .card_image {
    flex-shrink: 0;
    width: var(--width);
    height: calc(2 / 3 * var(--width));
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: $shadow;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .card_text {
    width: 100%;

    .card_name {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      font-weight: $semi-bold;
    }

    .tags {
      text-align: center;
    }
  }
}
